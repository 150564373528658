
import { Component, Prop, Vue } from 'vue-property-decorator'
import KickFromTeam from '@/components/modals/confirmations/kick_from_team.vue'
import TeamMember from '@/interfaces/team_member'

@Component
export default class ProxyMemberManager extends Vue {
  @Prop()
  member!: TeamMember

  @Prop()
  teamId!: string

  @Prop()
  userHasPermission!: boolean

  kick(): void {
    this.$modal.show(KickFromTeam, { details: this.member, teamId: this.teamId }, { }, { closed: () => { this.$emit('reload') } })
  }
}
