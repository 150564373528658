
import * as Sentry from '@sentry/vue'
import { Component } from 'vue-property-decorator'
import TeamMemberManager from '@/components/team/member_manager.vue'
import TeamNav from '@/components/team/nav.vue'
import ProxyMemberManager from '@/components/team/proxy_manager.vue'
import Team from '@/interfaces/team'
import TeamMember from '@/interfaces/team_member'
import TeamViewMixin from '@/mixins/team_view_mixin'

@Component({
  components: {
    TeamMemberManager,
    TeamNav,
    ProxyMemberManager,
  },
})
export default class TeamManagement extends TeamViewMixin {
  loading = true

  team!: Team

  get url(): string {
    return `/backend/api/team/${this.teamId}/`
  }

  // Retrieve the User's Character in the Team
  get userMember(): TeamMember | undefined {
    return this.team.members.find((teamMember: TeamMember) => teamMember.character.user_id === this.$store.state.user.id)
  }

  created(): void {
    this.fetchTeam(false)
  }

  proxyMembers(): TeamMember[] {
    return this.team.members.filter((tm: TeamMember) => tm.character.proxy)
  }

  realMembers(): TeamMember[] {
    return this.team.members.filter((tm: TeamMember) => !tm.character.proxy)
  }

  async fetchTeam(reload: boolean): Promise<void> {
    // Load the team data from the API
    try {
      const response = await fetch(this.url)
      if (response.ok) {
        // Parse the JSON into a team and save it
        this.team = (await response.json()) as Team
        this.loading = false
        if (reload) this.$forceUpdate()
        document.title = `Manage Members - ${this.team.name} - Savage Aim`
      }
      else {
        super.handleError(response.status)
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when fetching Team.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  async load(): Promise<void> {
    this.fetchTeam(true)
  }
}
