
import { Component, Prop, Vue } from 'vue-property-decorator'
import KickFromTeam from '@/components/modals/confirmations/kick_from_team.vue'
import Permissions from '@/components/modals/permissions.vue'
import TeamMember from '@/interfaces/team_member'

@Component
export default class TeamMemberManager extends Vue {
  @Prop()
  member!: TeamMember

  @Prop()
  teamId!: string

  @Prop()
  userIsLead!: boolean

  editPermissions(): void {
    this.$modal.show(Permissions, { member: this.member, teamId: this.teamId }, { }, { closed: () => { this.$emit('reload') } })
  }

  kick(): void {
    this.$modal.show(KickFromTeam, { details: this.member, teamId: this.teamId }, { }, { closed: () => { this.$emit('reload') } })
  }
}
