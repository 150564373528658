
import * as Sentry from '@sentry/vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TeamMember from '@/interfaces/team_member'

@Component
export default class Permissions extends Vue {
  @Prop()
  member!: TeamMember

  @Prop()
  teamId!: number

  get url(): string {
    return `/backend/api/team/${this.teamId}/member/${this.member.id}/permissions/`
  }

  async save(): Promise<void> {
    const checkboxes = this.$el.querySelectorAll('input[type="checkbox"]')
    let permissions = 0
    checkboxes.forEach((el: Element) => {
      const input = el as HTMLInputElement
      if (input.checked) permissions += parseInt(input.value, 10)
    })

    // Send a request to update the permissions of the specified user
    try {
      const response = await fetch(this.url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': Vue.$cookies.get('csrftoken'),
        },
        body: JSON.stringify({ permissions }),
      })

      if (response.ok) {
        // Empty response so we can just reload the page
        this.$notify({ text: 'Permissions successfully updated!', type: 'is-success' })
        this.$emit('close')
      }
      else {
        this.$notify({ text: `Unexpected response ${response.status} when attempting to update Permissions.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when attempting to update Permissions.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }
}
